import React, { useContext, memo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, ResponsiveContext, ThemeContext } from 'grommet';
import loadable from '@loadable/component';

import {
  isPaint,
  isPrimer,
  isSupply,
  getProductPath,
  findImage,
} from '../../lib/product';
import extractGid from '../../lib/extractGid';
import CollectionProductCount from '../Collection/CollectionProductCount';
import ResponsiveGrid from '../ResponsiveGrid';

const ProductCardHoverDetails = loadable(() =>
  import('./ProductCardHoverDetails')
);
const PaintCollectionCard = loadable(() =>
  import('../Paint/PaintCollectionCard')
);
const ProductCard = loadable(() => import('../Product/ProductCard'));
const DesignerItemProductCard = loadable(() =>
  import('../Product/DesignerItemProductCard')
);

const ProductComponent = ({
  collection,
  product,
  paintCollections = [],
  imageSizes,
}) => {
  if (isPaint(product) && !isPrimer(product)) {
    const paintCollection = paintCollections.find(
      (c) =>
        c.products
          .map((p) => p.shopifyId)
          .indexOf(extractGid(product.shopifyId)) > -1
    );
    return (
      <PaintCollectionCard
        product={product}
        collectionContext={collection}
        collection={paintCollection}
        srcSetSizes={imageSizes}
      />
    );
  } else if (isSupply(product) || isPrimer(product)) {
    return (
      <ProductCardHoverDetails
        product={product}
        collection={collection}
        srcSetSizes={imageSizes}
      />
    );
  } else if (collection.template?.value === 'Designer Items') {
    return (
      <DesignerItemProductCard
        collectionImageUrl={
          findImage(
            product.media.map(({ image }) => image),
            '_grid'
          ) ||
          findImage(
            product.media.map(({ image }) => image),
            '_hero'
          )
        }
        title={product.title}
        collection={collection}
        detailsPath={getProductPath(product)}
        price={product.priceRangeV2.minVariantPrice.amount}
        sizes={imageSizes}
        {...product}
      />
    );
  } else {
    return (
      <ProductCard
        collectionImageUrl={product.media[0]?.image?.originalSrc}
        title={product.title}
        collection={collection}
        detailsPath={getProductPath(product)}
        imageFill={true}
        price={product.priceRangeV2.minVariantPrice.amount}
        sizes={imageSizes}
        showPrice
      />
    );
  }
};

ProductComponent.propTypes = {
  product: PropTypes.object.isRequired,
  collection: PropTypes.object,
  paintCollections: PropTypes.array,
  imageSizes: PropTypes.string,
};

const ProductCollectionGrid = ({
  products,
  collection,
  paintCollections,
  maxCols = 4,
  ...rest
}) => {
  const size = useContext(ResponsiveContext);
  const theme = useContext(ThemeContext);
  const colCount =
    size === 'small'
      ? 1
      : size === 'medsmall'
      ? 2
      : size === 'medium'
      ? 3
      : size === 'large'
      ? maxCols
      : maxCols;
  const gapSize = size || 'large';
  const imageSizes = `(min-width: ${
    theme.global.breakpoints.medium.value
  }px) calc(${100 / maxCols}vw - ${theme.global.edgeSize.large} - ${
    theme.global.edgeSize.large
  }), (min-width: ${theme.global.breakpoints.medsmall.value}px) calc(33vw - ${
    theme.global.edgeSize.large
  } - ${theme.global.edgeSize.large}),
  (min-width: ${theme.global.breakpoints.small.value}px) calc(50vw - ${
    theme.global.edgeSize.large
  } - ${theme.global.edgeSize.large}), 100vw`;
  return (
    <Box>
      <ResponsiveGrid
        columns={{ count: colCount, size: 'auto' }}
        mobileColumns={{ count: 1, size: 'auto' }}
        gap={size === 'small' ? 'large' : gapSize}
        pad="large"
        {...rest}
      >
        {products.map((x) => (
          <Box height={{ min: '33vw' }} key={x.id}>
            <ProductComponent
              product={x}
              collection={collection}
              paintCollections={paintCollections}
              imageSizes={imageSizes}
            />
          </Box>
        ))}
      </ResponsiveGrid>
      <CollectionProductCount count={collection.products.length} />
    </Box>
  );
};

ProductCollectionGrid.propTypes = {
  products: PropTypes.array.isRequired,
  collection: PropTypes.object.isRequired,
  paintCollections: PropTypes.array,
  productComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
  maxCols: PropTypes.number,
};

export default memo(ProductCollectionGrid);
